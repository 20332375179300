export const PAGE_CONTENTS = [
  {
    url: '/refill/order/',
    title: 'order',
  },
  {
    url: '/refill/',
    title: 'scanner',
  },
  {
    url: '/refill/home/',
    title: 'home',
  },
  {
    url: '/refill/topup/',
    title: 'topup',
  },
  {
    url: '/',
    title: 'landing',
  },
  {
    url: '/refill/history/',
    title: 'history',
  },
];
