import React from "react"
import AppHeader from "../components/app/AppHeader"
import SEO from "../components/SEO"
import "../scss/style.scss"

const AppLayout = (props) => {
  return (
    <React.Fragment>
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
      <SEO />
      <div className={`page${props.bodyClass ? ` ${props.bodyClass}` : ""}`}>
        <div id="wrapper" className="wrapper">
          <AppHeader />
          {props.children}
        </div>
      </div>
    </React.Fragment>
  )
}

export default AppLayout
