import {Router} from '@reach/router';
import React from 'react';
import AppLayout from '../layouts/AppLayout';
import Fill from './refill/fill';
import Login from './refill/login';
import Order from './refill/order';
import Register from './refill/register';
import Scanner from './refill/scanner';
import Home from './refill/home';
import NotFound from './refill/404';
import Topup from './refill/topup';
import History from './refill/history';

const Refill = () => {
  return (
    <AppLayout>
      <Router basepath="/refill">
        <Scanner path="/" />
        <Order path="/order" />
        <Fill path="/fill" />
        <Home path="/home" />
        <Login path="/login" />
        <Register path="/register" />
        <Topup path="/topup" />
        <History path="/history" />
        <NotFound default />
      </Router>
    </AppLayout>
  );
};
export default Refill
;
