import {navigate, useLocation} from '@reach/router';
import React, {useEffect, useState} from 'react';
import {Link as Link2} from 'react-scroll';
import back from 'src/images/app/back.png';
import brandLogo from 'src/images/app/xl-logo.png';
import homeIcon from 'src/images/app/icon_home.png';
import logo from 'src/images/logo.png';
import {STATIONS_LOGO, STATION_CODE_KEY} from '../../helpers/constants';
import {PAGE_CONTENTS} from '../../helpers/pages';
import {getItemFromStorage} from '../../helpers/localStorage';
import {navigateToHome, navigateToRefill} from '../../helpers/navigation';
import Helmet from 'react-helmet';

function AppHeader() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isShowLogo, showLogo] = useState(false);
  const [appLogo, setAppLogo] = useState(logo);
  const [titlePrefix, setTitlePrefix] = useState(false);
  const [ContentPath] = useState(PAGE_CONTENTS);

  // const logo = STATIONS_LOGO[]
  const location = useLocation();

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const onBackClick = () => {
    if (titlePrefix === 'order') {
      navigateToRefill();
      return;
    }
    navigate(-1);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {passive: true});

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const stationCode = getItemFromStorage(STATION_CODE_KEY);
    if (stationCode === 'wakafxl') showLogo(true); else showLogo(false);
    // eslint-disable-next-line max-len
    const appLogo = STATIONS_LOGO.find((logo) => logo.stationName == stationCode);

    appLogo && setAppLogo(appLogo.logo);
  }, [getItemFromStorage(STATION_CODE_KEY)]);

  useEffect(() => {
    const pathInfo = ContentPath.find((o) =>
      o.url === window.location.pathname.toString(),
    );

    if (pathInfo) setTitlePrefix(pathInfo.title);
  }, [location.pathname]);

  return (
    <>
      <Helmet>
        <meta
          name="izifill"
          content="izifill"
        />
        <title>{`${titlePrefix ? `${titlePrefix} | ` : ''}IZIFILL | Just Tap and Refill`}</title>
        <link rel="canonical" href="https://izifill.id/refill" />
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        <script type="text/javascript"
          src="https://app.midtrans.com/snap/snap.js"
          data-client-key="Mid-client-6J1FOqF9-Dy9a8IN"></script>
      </Helmet>
      <div className={`header-app ${scrollPosition > 0 ? 'd-none' : ''}`}>
        <div className="container d-flex justify-content-between">
          <div onClick={onBackClick}
            className="header__back">
            <img alt="Close Refill" src={back} />
          </div>
          <div className="logo-app">
            <Link2
              activeClass="active"
              to="section-home"
              smooth={true}
              spy={true}
              duration={500}
              offset={-60}>
              <img alt="Logo" src={appLogo} />
            </Link2>
          </div>
          <div style={{'cursor': 'pointer'}} onClick={() => navigateToHome()} >
            {isShowLogo && <img alt="brand-logo" width={48} src={brandLogo} />}
            {!isShowLogo && <img alt="home" width={36} src={homeIcon} />}
          </div>
        </div>
      </div>
    </>
  );
}

export default AppHeader;
