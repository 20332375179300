import React, {useEffect, useState} from 'react';
import Button from 'src/components/Button';
import {getByFetch, poll, post} from 'src/helpers/api';
import {DEMO_STATIONS, UNAUTHORIZED_STATUS_CODE} from 'src/helpers/constants';
import {defaultTemp, defaultWaterOption} from 'src/helpers/default';
import {FILL_URL, IS_FILL_ENABLE_URL, GET_USER_URL} from 'src/helpers/endpoints';
import {navigateToOrder, navigateToRefill, navigateToHome} from 'src/helpers/navigation';
import PutTumblrFallbackPng from 'src/images/app/put_tumblr_fallback.png';
import PutTumblrFallbackWebp from 'src/images/app/put_tumblr_fallback.webp';
import PutTumblrVideo from 'src/images/app/put_tumblr_new.mp4';
import FeedbackPopup from '../../components/app/FeedbackPopup';
import {UNLIMITED_REFILL_STATIONS} from '../../helpers/constants';

const Fill = (props) => {
  const {state = {}} = props.location;
  if (state === null) {
    navigateToRefill();
  }

  const waterOption = state !== null ? state.waterOption : defaultWaterOption;
  const temp = state !== null ? state.temp : defaultTemp;
  const isFree = process.env.IS_FREE === 'true';

  const [isTumblrDetected, setTumblrDetected] = useState(false);
  const [isFilling, setIsFilling] = useState(false);
  const [isShowFeedback, setShowFeedback] = useState(false);
  const [email, setEmail] = useState(null);

  async function fetchUserData() {
    const response = await getByFetch(GET_USER_URL);
    const isAuthorized = response.status !== UNAUTHORIZED_STATUS_CODE;

    if (isAuthorized) {
      const data = await response.json();
      setEmail(data.email);
    }
  }

  const postFillRequest = () => {
    if (isFilling) return;
    setIsFilling(true);

    setTimeout(() => {
      if (!UNLIMITED_REFILL_STATIONS.includes(state.stationCode)) {
        setShowFeedback(true);
      }
    }, 2000);

    const url = FILL_URL;
    post(url, {fillNow: 'true', waterStationId: state.stationCode})
        .then(function() {
          pollEnableAfterFillRequest();
        });
  };

  const pollEnableAfterFillRequest = () => {
    poll(isFillAlreadyDone, 60000, 2000, cancelFillWater).then(function() {
      console.log('fill already done, navigate to home page');
      setIsFilling(false);

      if (!UNLIMITED_REFILL_STATIONS.includes(state.stationCode)) {
        setShowFeedback(true);
      } else onFeedbackDone();
    });
  };

  const onFeedbackDone = () => {
    navigateToHome({stationCode: state.stationCode});
  };

  let doneFilling = false;
  function isFillAlreadyDone() {
    const url = IS_FILL_ENABLE_URL + state.stationCode;
    getByFetch(url)
        .then((response) => response.json())
        .then((data) => {
          if (data === false) {
            doneFilling = true;
          }
        });

    return doneFilling;
  }

  const pollTumblrDetector = () => {
    poll(isFillEnable, 60000, 1000, cancelFillWater).then(function() {
      console.log('tumblr detected, hide tumblr detector');
      setTumblrDetected(true);
    });
  };

  const cancelFillWater = () => {
    const url = FILL_URL;

    post(url, {fillNow: 'timeout', waterStationId: state.stationCode});
    console.log('timed out, navigate to order');
    navigateToOrder(
        {stationCode: state.stationCode, waterOption: waterOption, temp: temp},
    );
  };

  const onClickTumblrPopup = () => {
    if (DEMO_STATIONS.includes(state.stationCode)) {
      setTumblrDetected(true);
    }
  };

  let isEnable = false;
  function isFillEnable() {
    const url = IS_FILL_ENABLE_URL + state.stationCode;
    getByFetch(url)
        .then((response) => response.json())
        .then((data) => {
          if (data === true) {
            isEnable = true;
          }
        });

    return isEnable;
  }

  useEffect(() => {
    pollTumblrDetector();
    fetchUserData();
  }, []);

  return (
    <div className="fill">
      <div className="d-flex flex-column align-items-center mt-10 fill-info w-100">
        <h5>Yeah, Your Water is Ready</h5>
        <div className="d-flex w-100 justify-content-center">
          <div className="fill-info-icon-container d-flex flex-column align-items-center">
            <img className="fill-info-temp-icon" alt="temp type" src={temp && temp.icon}></img>
            <img className="fill-info-tumblr-icon" alt="tumbler type" src={waterOption && waterOption.icon}></img>
          </div>
          <div className="mt-1 py-1 d-flex flex-column align-items-center justify-content-center fill-info-order-summary">
            <p className="my-0">{waterOption && waterOption.volume} ml</p>
            <p className="my-0">{temp && temp.name} Water</p>
            {isFree ?
              <div className="d-flex flex-column align-items-center">
                <p className="my-0">for just <strike>Rp {waterOption && waterOption.price}</strike> </p>
                <p className="my-0">FREE!</p>
              </div> :
              <p className="my-0">for just Rp {waterOption && waterOption.price}</p>
            }

          </div>
        </div>
        <Button
          onClick={postFillRequest}
          className="mt-5 d-flex justify-content-center"
          text={isFilling ? 'FILLING ON PROCESS ...' : 'FILL'}
          buttonClassName="button-secondary"
          isEnable={!isFilling}
        />
      </div>
      <div onClick={onClickTumblrPopup} className={`fill-tumblr-detector-container ${isTumblrDetected ? 'd-none' : 'd-block'}`}>
        <video className="p-2 fill-tumblr-detector" autoPlay loop muted playsInline>
          <source src={PutTumblrVideo} type="video/mp4" />
          <img src={PutTumblrFallbackWebp} alt="Put your Tumblr on" />
          <img src={PutTumblrFallbackPng} alt="Put your Tumblr on" />
        </video>
      </div>
      { isShowFeedback &&
        !UNLIMITED_REFILL_STATIONS.includes(state.stationCode) &&
        <FeedbackPopup className="fill-feedback__container" onFeedbackDone={onFeedbackDone} stationCode={state.stationCode} email={email}/>
      }
    </div>
  );
};
export default Fill;
